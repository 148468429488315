import { useSelector } from "react-redux";
import { RootState } from "@state/store";
import { Navigate } from "react-router-dom";
import { RoleTypeEnum } from "@services/user-manage/enum/user.enum";

interface Prop {
  children: any;
}
const AdminGuard: React.FC<Prop> = ({ children }) => {
  const loginUser = useSelector((state: RootState) => state.global.loginUser);

  if (loginUser && loginUser.role !== RoleTypeEnum.Normal) {
    return children;
  } else {
    return <Navigate to="/dashboard/projects" replace />;
  }
};

export default AdminGuard;
