export enum RegistrationStatusEnum {
  Pending = 1,
  Approved = 5,
  Denied = 6,
  UserCreated = 7,
  Deleted = 99,
}

export enum CategoryValuesEnum {
  SmallMolDevelop = "small_mol_develop",
  Antibody = "antibody",
  Xdc = "XDC",
  Peptide = "peptide",
  EnzymeOrSyntheticBiology = "enzyme_or_synthetic_biology",
  Other = "other",
}

export enum DevelopmentPhaseEnum {
  MolExplore = "mol_explore",
  MolFilter = "mol_filter",
  Optimize = "optimize",
  Other = "other",
}

export enum PriorityEnum {
  WantTryNow = "want_try_now",
  WantDeeperCommunication = "want_deeper_communication",
  MayTryLater = "may_try_later",
  NotSure = "not_sure",
}
