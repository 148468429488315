import { Outlet } from "react-router-dom";
import HomeHeader from "@layouts/components/home-header";
import HomeFooter from "@layouts/components/home-footer";

const HomeLayout = () => {
  return (
    <div className="page">
      <HomeHeader />
      <div>
        <Outlet />
      </div>
      <HomeFooter />
    </div>
  );
};

export default HomeLayout;
