export enum NoticeLevelEnum {
  Error = "error",
  Warning = "warning",
  Info = "info",
  Success = "success",
}

export enum NoticeTypeEnum {
  Job = "Job",
  Resource = "Resource",
  Balance = "Balance",
  Global = "Global",
}
