import { NoticeLevelEnum, NoticeTypeEnum } from "../enum/notification.enum";
import { LabelValueModel } from "@services/common/model/global.model";

export const NOTICE_LEVEL_LIST: LabelValueModel[] = [
  {
    id: 0,
    label: NoticeLevelEnum.Error,
    value: NoticeLevelEnum.Error,
  },
  {
    id: 1,
    label: NoticeLevelEnum.Warning,
    value: NoticeLevelEnum.Warning,
  },
  {
    id: 2,
    label: NoticeLevelEnum.Info,
    value: NoticeLevelEnum.Info,
  },
  {
    id: 3,
    label: NoticeLevelEnum.Success,
    value: NoticeLevelEnum.Success,
  },
];

export const FEEDBACK_LEVEL_LIST: LabelValueModel[] = [
  {
    id: 0,
    label: "Bug",
    value: NoticeLevelEnum.Error,
  },
  {
    id: 1,
    label: "Pain point",
    value: NoticeLevelEnum.Warning,
  },
  {
    id: 2,
    label: "Feature request",
    value: NoticeLevelEnum.Info,
  },
  {
    id: 3,
    label: "Praise",
    value: NoticeLevelEnum.Success,
  },
];

export const NOTICE_TYPE_LIST: LabelValueModel[] = [
  {
    id: 0,
    label: NoticeTypeEnum.Job,
    value: NoticeTypeEnum.Job,
  },
  {
    id: 1,
    label: NoticeTypeEnum.Resource,
    value: NoticeTypeEnum.Resource,
  },
  {
    id: 2,
    label: NoticeTypeEnum.Balance,
    value: NoticeTypeEnum.Balance,
  },
  {
    id: 3,
    label: NoticeTypeEnum.Global,
    value: NoticeTypeEnum.Global,
  },
];
