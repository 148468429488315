import React from "react";
import { Typography, Modal, Toast, Tooltip } from "@douyinfe/semi-ui";
import { ReactComponent as DeleteIcon } from "@assets/icons/delete.svg";
import { ReactComponent as DangerIcon } from "@assets/icons/danger.svg";
import { ReactComponent as WarningIcon } from "@assets/icons/warning.svg";
import { ReactComponent as SuccessIcon } from "@assets/icons/success.svg";
import { ReactComponent as InfoIcon } from "@assets/icons/info.svg";
import { useSelector } from "react-redux";
import { RootState } from "@state/store";
import { NotificationModel } from "@services/notification/model/notification.model";
import { NoticeLevelEnum } from "@services/notification/enum/notification.enum";
import {
  markNotificationReadApi,
  deleteNotificationApi,
} from "@services/notification/notification.api";
import TimezoneFormat from "@components/timezone-format";
import { RoleTypeEnum } from "@services/user-manage/enum/user.enum";
import styles from "./index.module.css";

const { Text } = Typography;

interface Prop {
  notice: NotificationModel;
  fromDropdown?: boolean;
  markRead?: (notice: NotificationModel) => void;
  remove?: (notice: NotificationModel) => void;
}

const NotificationPanel: React.FC<Prop> = ({
  notice,
  markRead = () => {},
  remove = () => {},
  fromDropdown = false,
}) => {
  const loginUser = useSelector((state: RootState) => state.global.loginUser);

  function onNoticeClick(note: NotificationModel) {
    markNotificationReadApi(note.id)
      .then(({ code }) => {
        if (code === 200) {
          markRead(note);
        }
      })
      .catch(() => {});
  }

  function onDelete(
    e: React.MouseEvent<SVGSVGElement>,
    note: NotificationModel
  ) {
    e.stopPropagation();
    const model = Modal.warning({
      title: "Warning",
      content: `Are you sure you want to delete this notification? This action cannot be undone.`,
      onOk: async () => {
        const { code, message } = await deleteNotificationApi(note.id);
        if (code === 200) {
          model.destroy();
          Toast.success(message);
          remove(note);
        }
      },
      okText: "Delete",
      cancelText: "Cancel",
    });
  }

  function onExpand(expanded: boolean, e: React.MouseEvent<any>) {
    e.stopPropagation();
  }

  const RenderIcon = ({ level }: { level: NoticeLevelEnum }) => {
    if (level === NoticeLevelEnum.Error) {
      return <DangerIcon className={styles.danger} />;
    } else if (level === NoticeLevelEnum.Info) {
      return <InfoIcon className={styles.info} />;
    } else if (level === NoticeLevelEnum.Success) {
      return <SuccessIcon className={styles.success} />;
    } else {
      return <WarningIcon className={styles.warning} />;
    }
  };

  return (
    <div
      onClick={() => onNoticeClick(notice)}
      key={notice.id}
      className={`${styles.panel} ${notice.read ? styles.read : ""}`}
    >
      <div className={styles.iconContainer}>
        <RenderIcon level={notice.level} />
      </div>
      <div className={styles.contentContainer}>
        <div className={styles.titleContainer}>
          <div className={styles.title}>{notice.title}</div>
          <div className={styles.time}>
            {!fromDropdown && (
              <>
                {loginUser && loginUser.role !== RoleTypeEnum.Normal && (
                  <span style={{ marginRight: "16px" }}>
                    from: {notice.sender}
                  </span>
                )}

                <span style={{ marginRight: "12px" }}>
                  <TimezoneFormat timestamp={notice.sent_time} />
                </span>
              </>
            )}

            <Tooltip content="Delete">
              <DeleteIcon
                className={styles.deleteBtn}
                onClick={(e) => onDelete(e, notice)}
              />
            </Tooltip>
          </div>
        </div>
        <Text
          className={styles.desc}
          ellipsis={{
            rows: 1,
            expandable: true,
            collapsible: true,
            onExpand: onExpand,
          }}
        >
          {notice.message}
        </Text>

        {fromDropdown && (
          <div className={styles.footer}>
            <span>
              <TimezoneFormat timestamp={notice.sent_time} />
            </span>
            {loginUser && loginUser.role !== RoleTypeEnum.Normal && (
              <span>from: {notice.sender}</span>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default NotificationPanel;
