export const lazyRetry = function (componentImport: () => Promise<any>) {
  return new Promise<any>((resolve, reject) => {
    const hasRefreshed = JSON.parse(
      window.sessionStorage.getItem("retry-lazy-refreshed") || "false"
    ) as boolean;

    componentImport()
      .then((component) => {
        window.sessionStorage.setItem("retry-lazy-refreshed", "false");
        resolve(component);
      })
      .catch((error) => {
        console.log("lazyRetry-->", error);
        if (!hasRefreshed) {
          window.sessionStorage.setItem("retry-lazy-refreshed", "true");
          return window.location.reload();
        }

        reject(error);
      });
  });
};
