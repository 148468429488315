import { useState, useEffect, useLayoutEffect } from "react";
import { RootState } from "@state/store";
import { useSelector } from "react-redux";
import tradeMark from "@assets/home-image/trademark.svg";
import qrCode from "@assets/home-image/qrcode.png";
import { footerConfig } from "@services/home/config/home.config";
import { HomeFooterModel } from "@services/home/model/home.model";

import styles from "./index.module.css";

const HomeFooter = () => {
  const lang = useSelector((state: RootState) => state.home.lang);

  const [config, setConfig] = useState<HomeFooterModel>();
  const [year, setYear] = useState("");

  useEffect(() => {
    const date = new Date();
    setYear(`${date.getFullYear()}`);
  }, []);

  useLayoutEffect(() => {
    if (lang) {
      setConfig(footerConfig[lang]);
    }
  }, [lang]);

  return (
    <div className={styles.footer}>
      {config && (
        <div className={styles.content}>
          <div className={styles.row}>
            <div className={styles.col}>
              <a href="https://www.biogeom.com" target="_black">
                <img src={tradeMark} style={{ width: "176px" }} />
              </a>

              <div className={styles.textTitle}>{config.columns[0].title}</div>
              <div className={styles.subText}>{config.columns[0].text}</div>
            </div>
            <div className={styles.col}>
              <div className={styles.textTitle}>{config.columns[1].title}</div>
              <div className={styles.subText}>{config.columns[1].text}</div>
            </div>
            <div className={styles.col}>
              <div className={styles.textTitle}>{config.columns[2].title}</div>
              <div className={styles.subText}>{config.columns[2].text}</div>
            </div>
            <div
              className={styles.col}
              style={{ textAlign: lang === "cn" ? "center" : "right" }}
            >
              <img src={qrCode} className={styles.qrcode} />
              <div className={styles.subText} style={{ marginTop: "11px" }}>
                {config.label}
              </div>
            </div>
          </div>
          <div className={styles.copyRightWrapper}>
            Copyright&nbsp;©&nbsp;{year}&nbsp;
            <a
              href="https://www.biogeom.com"
              target="_black"
              className={styles.link}
            >
              百奥几何
            </a>
            &nbsp;|&nbsp;
            <a
              href="https://beian.miit.gov.cn/#/Integrated/index"
              target="_black"
              className={styles.link}
            >
              京ICP备2022032231号-1
            </a>
            &nbsp;|&nbsp;
            <a
              href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010802040974"
              target="_black"
              className={styles.link}
            >
              京公网安备 11010802040974号
            </a>
            &nbsp;|&nbsp;隐私条款
          </div>
        </div>
      )}
    </div>
  );
};

export default HomeFooter;
