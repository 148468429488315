import http, { request } from "@config/http";
import { Page } from "@services/common/model/page.model";
import { encrypt } from "@utils/encryption.util";
import {
  LoginPayloadModel,
  RegisterPayloadModel,
  TokenModel,
  ForgetPasswordEmailModel,
  ForgetPasswordPhoneModel,
} from "./model/login.model";

export const sendEmailApi = async (email: string) => {
  return http.post<null, Page<null>>(`user/send_email`, {
    email: encrypt(email),
  });
};

export const sendSmsApi = async (phone: string, passport?: string) => {
  return http.post<null, Page<null>>(`user/send_sms`, {
    phone: encrypt(phone),
    passport,
  });
};

export const checkUserNameApi = async (username: string) => {
  return http.post<null, Page<null>>(`user/check_username/${username}`, {});
};

export const checkPhoneApi = async (phone: string) => {
  return request.post<null, Page<null>>(`user/check_phone`, {
    phone,
  });
};

export const checkEmailApi = async (email: string) => {
  return http.post<null, Page<null>>(`user/check_email`, {
    email,
  });
};

export const checkGithubApi = async (github: string) => {
  return http.post<null, Page<null>>(`user/check_github/${github}`, {});
};

export const loginApi = async (params: LoginPayloadModel) => {
  return http.post<null, Page<TokenModel>>(`user/login`, {
    ...params,
  });
};

export const registerApi = async (params: RegisterPayloadModel) => {
  return http.post<null, Page<RegisterPayloadModel>>(`user/register`, {
    ...params,
  });
};

export const logoutApi = async () => {
  return http.delete<null, Page<TokenModel>>(`user/logout`, {});
};

export const checkCodePhoneApi = async (phone: string, code_phone: string) => {
  return http.post<null, Page<null>>(`user/check_code_phone`, {
    phone,
    code_phone,
  });
};

export const checkCodeEmailApi = async (email: string, code_email: string) => {
  return http.post<null, Page<null>>(`user/check_code_email`, {
    email,
    code_email,
  });
};

export const resetPasswordByEmailApi = async (
  params: ForgetPasswordEmailModel
) => {
  return http.post<null, Page<null>>(`user/forget_password_email`, {
    ...params,
  });
};

export const resetPasswordByPhoneApi = async (
  params: ForgetPasswordPhoneModel
) => {
  return http.post<null, Page<null>>(`user/forget_password_phone`, {
    ...params,
  });
};
