import React, { useEffect, useState } from "react";
import { formatInTimeZone } from "date-fns-tz";
import { useSelector } from "react-redux";
import { RootState } from "@state/store";

interface Prop {
  timestamp: number;
  format?: string;
}

const TimezoneFormat: React.FC<Prop> = ({
  timestamp,
  format = "yyyy-MM-dd HH:mm:ss",
}) => {
  const loginUser = useSelector((state: RootState) => state.global.loginUser);

  const [formatedTime, setFormatedTime] = useState<string>("");

  useEffect(() => {
    if (loginUser?.timezone) {
      if (timestamp <= 0) {
        setFormatedTime("-");
      } else {
        const date = new Date(timestamp);
        const data = formatInTimeZone(date, loginUser.timezone, format);
        setFormatedTime(data);
      }
    }
  }, [timestamp, loginUser?.timezone]);

  return <>{formatedTime}</>;
};

export default TimezoneFormat;
