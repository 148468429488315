import {
  RegistrationStatusEnum,
  CategoryValuesEnum,
  DevelopmentPhaseEnum,
  PriorityEnum,
} from "@services/account/enum/registration.enum";
import { NameValueModel } from "@services/common/model/global.model";

export const REGISTRATION_STATUS_MAP = {
  [RegistrationStatusEnum.Deleted]: "Deleted",
  [RegistrationStatusEnum.Pending]: "Pending",
  [RegistrationStatusEnum.UserCreated]: "User Created",
  [RegistrationStatusEnum.Approved]: "Approved",
  [RegistrationStatusEnum.Denied]: "Denied",
};

export const REGISTRATION_STATUS_LIST = [
  {
    name: REGISTRATION_STATUS_MAP[RegistrationStatusEnum.Approved],
    value: RegistrationStatusEnum.Approved,
  },
  {
    name: REGISTRATION_STATUS_MAP[RegistrationStatusEnum.Pending],
    value: RegistrationStatusEnum.Pending,
  },
  {
    name: REGISTRATION_STATUS_MAP[RegistrationStatusEnum.UserCreated],
    value: RegistrationStatusEnum.UserCreated,
  },
  {
    name: REGISTRATION_STATUS_MAP[RegistrationStatusEnum.Denied],
    value: RegistrationStatusEnum.Denied,
  },
];

export const CATEGORY_VALUES_MAP = {
  [CategoryValuesEnum.SmallMolDevelop]: "Small molecules",
  [CategoryValuesEnum.Antibody]: "Antibody (mAb/sdAb/bsAb/etc)",
  [CategoryValuesEnum.Xdc]: "XDC（ADC/PDC/etc）",
  [CategoryValuesEnum.Peptide]: "Peptide",
  [CategoryValuesEnum.EnzymeOrSyntheticBiology]: "Enzyme/Synthetic Biology",
  [CategoryValuesEnum.Other]: "Others",
};

export const DEVELOPMENT_PHASE_MAP = {
  [DevelopmentPhaseEnum.MolExplore]: "Hit generation (AI-generated hits)",
  [DevelopmentPhaseEnum.MolFilter]:
    "Hit to lead (Property prediction and screening)",
  [DevelopmentPhaseEnum.Optimize]:
    "Lead optimization (Structure modeling, property optimization)",
  [DevelopmentPhaseEnum.Other]: "Others",
};

export const PRIORITY_MAP = {
  [PriorityEnum.WantTryNow]:
    "Yes, I would love to try GeoBiologics in an ongoing project.",
  [PriorityEnum.WantDeeperCommunication]:
    "We’re scoping a project for which GeoBiologics can be useful. Let’s discuss.",
  [PriorityEnum.MayTryLater]:
    "GeoBiologics seems promising and I would love to try it for my project in the future.",
  [PriorityEnum.NotSure]: "I’m unsure if GeoBiologics is relevant to my work.",
};

export const CATEGORY_VALUES_OPTIONS: NameValueModel[] = [
  {
    name: CATEGORY_VALUES_MAP[CategoryValuesEnum.SmallMolDevelop],
    value: CategoryValuesEnum.SmallMolDevelop,
  },
  {
    name: CATEGORY_VALUES_MAP[CategoryValuesEnum.Antibody],
    value: CategoryValuesEnum.Antibody,
  },
  {
    name: CATEGORY_VALUES_MAP[CategoryValuesEnum.Xdc],
    value: CategoryValuesEnum.Xdc,
  },
  {
    name: CATEGORY_VALUES_MAP[CategoryValuesEnum.Peptide],
    value: CategoryValuesEnum.Peptide,
  },
  {
    name: CATEGORY_VALUES_MAP[CategoryValuesEnum.EnzymeOrSyntheticBiology],
    value: CategoryValuesEnum.EnzymeOrSyntheticBiology,
  },
  {
    name: CATEGORY_VALUES_MAP[CategoryValuesEnum.Other],
    value: CategoryValuesEnum.Other,
  },
];

export const DEVELOPMENT_PHASE_OPTIONS: NameValueModel[] = [
  {
    name: DEVELOPMENT_PHASE_MAP[DevelopmentPhaseEnum.MolExplore],
    value: DevelopmentPhaseEnum.MolExplore,
  },
  {
    name: DEVELOPMENT_PHASE_MAP[DevelopmentPhaseEnum.MolFilter],
    value: DevelopmentPhaseEnum.MolFilter,
  },
  {
    name: DEVELOPMENT_PHASE_MAP[DevelopmentPhaseEnum.Optimize],
    value: DevelopmentPhaseEnum.Optimize,
  },
  {
    name: DEVELOPMENT_PHASE_MAP[DevelopmentPhaseEnum.Other],
    value: DevelopmentPhaseEnum.Other,
  },
];

export const PRIORITY_OPTIONS: NameValueModel[] = [
  {
    name: PRIORITY_MAP[PriorityEnum.WantTryNow],
    value: PriorityEnum.WantTryNow,
  },
  {
    name: PRIORITY_MAP[PriorityEnum.WantDeeperCommunication],
    value: PriorityEnum.WantDeeperCommunication,
  },
  {
    name: PRIORITY_MAP[PriorityEnum.MayTryLater],
    value: PriorityEnum.MayTryLater,
  },
  {
    name: PRIORITY_MAP[PriorityEnum.NotSure],
    value: PriorityEnum.NotSure,
  },
];

export const REGISTRATION_STATUS_COLOR = {
  [RegistrationStatusEnum.Approved]: "approved",
  [RegistrationStatusEnum.Deleted]: "",
  [RegistrationStatusEnum.Denied]: "denied",
  [RegistrationStatusEnum.Pending]: "pending",
  [RegistrationStatusEnum.UserCreated]: "created",
};
