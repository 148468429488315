import { Empty, Button } from "@douyinfe/semi-ui";
import { IconRefresh } from "@douyinfe/semi-icons";
import {
  IllustrationNoContent,
  IllustrationNoContentDark,
} from "@douyinfe/semi-illustrations";

const ErrorPage = () => {
  function onRefresh() {
    location.reload();
  }

  return (
    <Empty
      style={{
        height: "100vh",
        justifyContent: "center",
      }}
      image={<IllustrationNoContent style={{ width: 150, height: 150 }} />}
      darkModeImage={
        <IllustrationNoContentDark style={{ width: 150, height: 150 }} />
      }
      title="A newer version of GeoBiologics has been released."
      description="Please refresh to continue."
    >
      <div style={{ textAlign: "center" }}>
        <Button
          onClick={onRefresh}
          icon={<IconRefresh />}
          style={{ padding: "6px 24px" }}
          theme="solid"
          type="primary"
        >
          refresh
        </Button>
      </div>
    </Empty>
  );
};

export default ErrorPage;
