import { AES, enc } from "crypto-js";
import { passwordEncryptionKey } from "@config/global.config";

export function encrypt(cipherText: string): string {
  const encrptedText = AES.encrypt(cipherText, passwordEncryptionKey);
  return encrptedText.toString();
}

export function decrypt(encrptedText: string) {
  const decryptedText = AES.decrypt(encrptedText, passwordEncryptionKey);
  return decryptedText.toString(enc.Utf8);
}
