import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { UserModel } from "@services/user-manage/model/user.model";

export interface GlobalStateModel {
  siderCollapsed: boolean;
  loginUser: UserModel | null;
}

const initialState: GlobalStateModel = {
  siderCollapsed: false,
  loginUser: null,
};

export const globalSlice = createSlice({
  name: "global",
  initialState,
  reducers: {
    toggleSideBar: (state) => {
      state.siderCollapsed = !state.siderCollapsed;
    },

    setUser: (state, action: PayloadAction<UserModel>) => {
      state.loginUser = action.payload;
    },

    deleteUser: (state) => {
      state.loginUser = null;
    },

    changeRedDot: (state, action: PayloadAction<number>) => {
      if (state.loginUser) {
        state.loginUser.red_dot = action.payload;
      }
    },

    updateTimeZone(state, action: PayloadAction<string>) {
      if (state.loginUser) {
        state.loginUser.timezone = action.payload;
      }
    },
  },
});

export const {
  toggleSideBar,
  setUser,
  deleteUser,
  changeRedDot,
  updateTimeZone,
} = globalSlice.actions;

export default globalSlice.reducer;
