import axios from "axios";
import { Toast } from "@douyinfe/semi-ui";
import { getLocalStorage } from "@utils/localstorage";
import { LoginEnum } from "@services/login/enum/login.enum";
import { TokenModel } from "@services/login/model/login.model";
import type { AxiosResponse, AxiosError } from "axios";
import type { Page } from "@services/common/model/page.model";
import { API_HOST } from "@config/global.config";

export const axiosInstance = axios.create({
  baseURL: `${API_HOST}/api/`,
});

export const request = axios.create({
  baseURL: `${API_HOST}/api/`,
});

request.interceptors.request.use(
  (config) => {
    const tokenInfo = getLocalStorage(LoginEnum.Token) as TokenModel;
    if (tokenInfo) {
      config.headers.Authorization = tokenInfo.token;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

request.interceptors.response.use(
  function (response: AxiosResponse<Page<null>>) {
    const { data } = response;

    return data as any;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.request.use(
  (config) => {
    const tokenInfo = getLocalStorage(LoginEnum.Token) as TokenModel;
    if (tokenInfo) {
      config.headers.Authorization = tokenInfo.token;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  function (response: AxiosResponse<Page<null>>) {
    const { data } = response;
    const { code, message } = data;

    if (code !== 200 && code !== 401) {
      Toast.error(message);
    }

    if (code === 401) {
      const currentPath = location.pathname;
      window.location.href = `/login?redirect=${encodeURIComponent(
        currentPath
      )}`;
    }
    return data as any;
  },
  function (error: AxiosError<Page<null>, any>) {
    const { response } = error;
    if (!response) {
      return;
    }
    const { data } = response;
    const { code, message } = data;
    if (code !== 200 && code !== 401) {
      Toast.error(message);
    }

    if (code === 401) {
      const currentPath = location.pathname;
      window.location.href = `/login?redirect=${encodeURIComponent(
        currentPath
      )}`;
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
