import React from "react";
import { Navigate } from "react-router-dom";
import { useRequest } from "ahooks";
import { encrypt } from "@utils/encryption.util";
import { TokenModel } from "@services/login/model/login.model";
import { LoginEnum } from "@services/login/enum/login.enum";
import { getLocalStorage } from "@utils/localstorage";
import LoadIndicator from "@components/load-indicator";
import { getLoginUserInfo } from "@services/user-manage/user.api";

interface Prop {
  children: any;
}
const LogoutGuard: React.FC<Prop> = ({ children }) => {
  const { data, loading } = useRequest(getLoginUserInfo);

  if (loading) {
    return <LoadIndicator />;
  } else if (!loading && data && data.code === 200) {
    const urlParams = new URLSearchParams(window.location.search);
    const docUrl = urlParams.get("docUrl");

    const tokenInfo = getLocalStorage(LoginEnum.Token) as TokenModel;

    if (docUrl) {
      const { origin, pathname } = new URL(decodeURIComponent(docUrl));

      window.location.href = `${origin}${
        pathname === "/" ? "/index.html" : pathname
      }?referer=${encodeURIComponent(
        window.location.href
      )}&token=${encodeURIComponent(encrypt(tokenInfo.token))}`;
    } else {
      return <Navigate to="/dashboard/projects" replace />;
    }
  } else {
    return children;
  }
};

export default LogoutGuard;
