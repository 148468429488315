import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export interface HomeState {
  lang: string;
}

const initialState: HomeState = {
  lang: "",
};

export const HomeSlice = createSlice({
  name: "home",
  initialState,

  reducers: {
    setLang: (state, action: PayloadAction<string>) => {
      state.lang = action.payload;
    },
  },
});

export const { setLang } = HomeSlice.actions;

export default HomeSlice.reducer;
