export enum PlanEnum {
  Plan1 = "Plan1",
  Plan2 = "Plan2",
  custom = "custom",
}

export enum RoleTypeEnum {
  SysAdmin = "sys_admin",
  OrgAdmin = "org_admin",
  Normal = "normal",
}

export enum UserStateEnum {
  Inactive = "inactive",
  Active = "active",
  Disabled = "disabled",
}

export enum UserStatusCodeEnum {
  Success = 200,
  Disabled = 201,
  Inactive = 202,
}
