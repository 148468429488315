import ReactDOM from "react-dom/client";
import { RouterProvider } from "react-router-dom";
import { LocaleProvider } from "@douyinfe/semi-ui";
import en_GB from "@douyinfe/semi-ui/lib/es/locale/source/en_GB";
import "./index.css";
import "@scss/global.scss";
import reportWebVitals from "./reportWebVitals";
import router from "./routes";
import { store } from "./state/store";
import { Provider } from "react-redux";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <LocaleProvider locale={en_GB}>
      <RouterProvider router={router} />
    </LocaleProvider>
  </Provider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
