import { useState, useEffect } from "react";
import { List, Spin, RadioGroup, Radio } from "@douyinfe/semi-ui";
import { useRequest } from "ahooks";
import InfiniteScroll from "react-infinite-scroller";
import {
  NotificationParamsModel,
  NotificationModel,
} from "@services/notification/model/notification.model";
import { getNotificationListApi } from "@services/notification/notification.api";
import styles from "./index.module.css";
import NotificationPanel from "@pages/notification-list/components/notification-panel";
import type { RadioChangeEvent } from "@douyinfe/semi-foundation/radio/radioInnerFoundation";

const NotificationScrollList = () => {
  const [hasMore, setHasMore] = useState(false);
  const [dataSource, setDataSource] = useState<NotificationModel[]>([]);

  const [params, setParams] = useState<NotificationParamsModel>({
    page: 1,
    page_size: 10,
    read: undefined,
  });

  function getList(p: NotificationParamsModel) {
    return getNotificationListApi(p);
  }

  const { loading, run } = useRequest(
    (p: NotificationParamsModel) => getList(p),
    {
      manual: true,
      onSuccess: ({ list, total }) => {
        if (list.length) {
          const showedList = [...dataSource, ...list];
          setDataSource(showedList);

          if (showedList.length === total) {
            setHasMore(false);
          } else {
            setHasMore(true);
          }
        }
      },
    }
  );

  function fetchData() {
    const p = {
      ...params,
      page: ++params.page,
    };
    run(p);
    setParams(p);
  }

  function onStatusChange(e: RadioChangeEvent) {
    console.log("onStatusChange", e.target.value);
    const p = {
      ...params,
      page: 1,
      read: e.target.value as boolean,
    };
    setDataSource([]);
    run(p);
    setParams(p);
  }

  function onMark(notice: NotificationModel) {
    const list = dataSource.map((item) => {
      return { ...item, read: notice.id === item.id ? true : item.read };
    });

    setDataSource(list);
  }

  function onRemove(notice: NotificationModel) {
    const list = dataSource.filter((item) => {
      return item.id !== notice.id;
    });

    setDataSource(list);
  }

  useEffect(() => {
    run(params);
  }, []);
  return (
    <div>
      <div className={styles.titleContainer}>
        <div>Notifications</div>
        <div>
          <RadioGroup
            onChange={onStatusChange}
            style={{
              background: "var(--semi-color-fill-0)",
            }}
            type="button"
            buttonSize="small"
            defaultValue={undefined}
          >
            <Radio value={undefined}>All</Radio>
            <Radio value={0}>Unread</Radio>
          </RadioGroup>
        </div>
      </div>
      <div className={styles.listContainer}>
        {/* eslint-disable */}
        {/* @ts-ignore: Unreachable code error */}
        <InfiniteScroll
          initialLoad={false}
          pageStart={0}
          threshold={250}
          loadMore={fetchData}
          hasMore={!loading && hasMore}
          useWindow={false}
        >
          <List
            loadMore={null}
            dataSource={dataSource}
            renderItem={(item) => (
              <List.Item
                main={
                  <NotificationPanel
                    notice={item}
                    fromDropdown={true}
                    markRead={onMark}
                    remove={onRemove}
                  />
                }
              />
            )}
          />
          {loading && hasMore && (
            <div style={{ textAlign: "center" }}>
              <Spin />
            </div>
          )}
        </InfiniteScroll>
        {/* eslint-enable */}
      </div>
    </div>
  );
};

export default NotificationScrollList;
