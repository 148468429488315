import { configureStore } from "@reduxjs/toolkit";

import editorReducer from "./protein-editor/reducer";
import globalReducer from "./global/reducer";
import HomeReducer from "./home/reducer";

export const store = configureStore({
  reducer: {
    editor: editorReducer,
    global: globalReducer,
    home: HomeReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
