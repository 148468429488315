import React, { useRef, useState } from "react";
import { Toast, Modal, Form } from "@douyinfe/semi-ui";
import { FEEDBACK_LEVEL_LIST } from "@services/notification/config/notification.config";
import { NoticeLevelEnum } from "@services/notification/enum/notification.enum";
import { FeedbackModel } from "@services/notification/model/notification.model";
import { sendFeedbackApi } from "@services/notification/notification.api";
import type {
  BaseFormApi as FormApi,
  FormState,
} from "@douyinfe/semi-foundation/lib/es/form/interface";

const { Option } = Form.Select;

interface Prop {
  cancel: () => void;
  submit: () => void;
}

const FeedbackDialog: React.FC<Prop> = ({ cancel, submit }) => {
  const formRef = useRef<FormApi<FeedbackModel>>();
  const [loading, setLoading] = useState(false);

  function handleCancel() {
    cancel();
  }

  function handleSubmit() {
    formRef.current
      ?.validate()
      .then((values: FeedbackModel) => {
        setLoading(true);
        sendFeedbackApi(values)
          .then(({ code, message }) => {
            setLoading(false);
            if (code === 200) {
              Toast.success(message);
              formRef.current?.reset();
              submit();
            }
          })
          .catch(() => {});
      })
      .catch(() => {});
  }

  return (
    <Modal
      title="Feedback"
      visible={true}
      onCancel={handleCancel}
      closeOnEsc={true}
      width="450px"
      centered
      hasCancel={false}
      okText="Submit"
      onOk={handleSubmit}
      confirmLoading={loading}
    >
      <div style={{ color: "var(--semi-color-text-1)" }}>
        Share your feedback to help us improve GeoBiologics.
      </div>
      <Form
        layout="vertical"
        getFormApi={(formApi) => (formRef.current = formApi)}
        initValues={{ level: NoticeLevelEnum.Error }}
      >
        {() => (
          <>
            <Form.Input
              label="Title"
              rules={[{ required: true, message: "Required error" }]}
              field="title"
            />

            <Form.Select
              clickToHide={true}
              field="level"
              label="Level"
              style={{ width: "100%" }}
              rules={[{ required: true, message: "Required error" }]}
            >
              {FEEDBACK_LEVEL_LIST.map((item) => {
                return (
                  <Option value={item.value} key={item.id}>
                    {item.label}
                  </Option>
                );
              })}
            </Form.Select>

            <Form.TextArea
              autosize
              maxCount={1000}
              showClear
              field="message"
              label="Message"
              rules={[{ required: true, message: "Required error" }]}
            />
          </>
        )}
      </Form>
    </Modal>
  );
};

export default FeedbackDialog;
