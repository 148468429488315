const domain = `${window.location.protocol}//${window.location.host}`;

export const passwordEncryptionKey = "1234123412ABCDEF";

export const API_HOST = `${process.env.REACT_APP_API_HOST!}`;

export const LOGIN_PAGE = `${domain}/login`;

export const SIGNUP_PAGE = `${domain}/register`;

export const ACCOUNT_PAGE = `${domain}/account`;

export const DOC_URL = window.location.host.includes("geobiologics-cn")
  ? "https://geobiologics-docs-cn.biogeom.com"
  : "https://geobiologics-docs.biogeom.com";

// export const DOC_URL = "http://127.0.0.1:8000";
