import { Outlet } from "react-router-dom";
import { Layout } from "@douyinfe/semi-ui";
import HeaderSection from "./header";

const { Content } = Layout;
const EditorLayout = () => {
  return (
    <Layout
      style={{
        maxHeight: "100vh",
        minHeight: "396px",
        overflowY: "auto",
      }}
    >
      <HeaderSection />
      <Content
        style={{
          padding: "8px",
        }}
      >
        <Outlet />
      </Content>
    </Layout>
  );
};

export default EditorLayout;
